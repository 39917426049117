import { LitElement, html, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';

declare global {
    interface HTMLElementTagNameMap {
        'psw-logo': PswLogo;
    }
}

@customElement('psw-logo')
export class PswLogo extends LitElement {
    public render(): TemplateResult {
        return html`
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="221"
                height="36"
                viewBox="0 0 221 36"
                fill="none"
            >
                <g clip-path="url(#clip0_6511_548)">
                    <path
                        d="M8.07398 29.2231H0L6.46696 6.14807H23.0167L21.4421 11.6236H12.9987L11.9166 15.5504H20.3599L18.9343
                         20.5918H10.491L8.08046 29.2231H8.07398Z"
                        fill="#368727"
                    />
                    <path
                        d="M26.7556 29.2231H18.7723L23.5027 12.3623H31.4859L26.7556 29.2231ZM31.9201 10.7553H23.9433L25.2393
                         6.14807H33.2161L31.9201 10.7553Z"
                        fill="#368727"
                    />
                    <path
                        d="M47.459 29.2231H39.6312L40.1885 27.3374C38.6139 28.6658 37.0004 29.6248 34.4343 29.6248C31.8683
                         29.6248 30.1964 28.3872 30.1964 25.7563C30.1964 22.8468 31.3434 18.5182 33.0411 15.4856C34.2788 13.2306
                         36.5079 12.0513 39.1323 12.0513C41.3873 12.0513 43.0591 13.0427 43.7071 14.2804L45.9362 6.14807H53.9194L47.4525
                         29.2231H47.459ZM41.4585 17.3778C40.7458 17.3778 40.033 17.5981 39.6312 18.583C39.0415 20.0086 38.5167 21.3953
                         38.5167 22.7885C38.5167 23.4365 38.9832 23.9938 39.7543 23.9938C40.3699 23.9938 40.7782 23.8059 41.1151
                         23.592L42.7221 18.0517C42.4435 17.6499 41.951 17.3713 41.4521 17.3713L41.4585 17.3778Z"
                        fill="#368727"
                    />
                    <path
                        d="M69.9896 21.8619H58.9803C58.7664 22.7561 58.5461 23.3782 58.5461 24.091C58.5461 24.4344 58.6044 25.2962
                         59.719 25.2962C60.8335 25.2962 61.326 24.8621 61.7925 23.2227H69.6203C68.6289 28.0178 62.9395 29.6572 59.4468
                         29.6572C54.7165 29.6572 51.0359 28.9768 51.0359 24.4279C51.0359 21.9202 52.3059 17.7795 53.9713 15.5828C55.9801
                         12.952 59.4792 11.967 62.6933 11.967C67.6439 11.967 70.858 12.9844 70.858 17.1963C70.858 18.2202 70.4238 20.4752
                         69.9896 21.8684V21.8619ZM62.0064 16.2049C61.015 16.2049 60.3346 16.8853 59.9004 18.583H63.1792C63.27 18.2461
                         63.3931 17.7147 63.3931 17.4102C63.3931 16.4835 62.8358 16.2049 61.9999 16.2049H62.0064Z"
                        fill="#368727"
                    />
                    <path
                        d="M78.0053 29.2231H70.022L76.489 6.14807H84.4723L78.0053 29.2231Z"
                        fill="#368727"
                    />
                    <path
                        d="M88.5546 29.2231H80.5714L85.3017 12.3623H93.285L88.5546 29.2231ZM93.7191 10.7553H85.7358L87.0318
                         6.14807H95.0151L93.7191 10.7553Z"
                        fill="#368727"
                    />
                    <path
                        d="M108.066 12.3623L108.759 16.166H102.648L100.854 22.5682C100.698 23.0607 100.575 23.6828 100.575 24.0845C100.575
                         24.8297 100.886 25.0759 101.968 25.0759H103.672L102.499 29.2231H95.3845C93.0322 29.2231 92.138 28.0761 92.138
                         26.346C92.138 25.6332 92.2287 24.8621 92.4815 23.9938L96.81 8.62341H104.793L103.744 12.3688H108.072L108.066
                         12.3623Z"
                        fill="#368727"
                    />
                    <path
                        d="M118.835 12.3623L114.507 21.7064L114.63 12.3623H106.556L108.105 29.2231C107.91 30.208 107.58 30.7653 106.964
                         31.0439C106.284 31.3485 104.754 31.2902 104.217 31.2642H104.119L102.849 35.852H108.299C111.513 35.852 113.094
                         34.2385 115.382 30.6551L127.013 12.3753H118.848L118.835 12.3623Z"
                        fill="#368727"
                    />
                </g>
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M212.692 10.5408C212.692 8.32373 214.498 6.52612 216.698 6.52612C218.898 6.52612 220.692 8.32373 220.692
                     10.5408C220.692 12.7578 218.898 14.5554 216.698 14.5554C214.486 14.5554 212.692 12.7458 212.692 10.5408ZM213.409
                     10.5402C213.409 12.3618 214.88 13.8358 216.698 13.8358C218.515 13.8358 219.986 12.3618 219.986 10.5402C219.986
                     8.71863 218.515 7.2446 216.698 7.2446C214.88 7.2446 213.409 8.71863 213.409 10.5402Z"
                    fill="#368727"
                    fill-opacity="0.9"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M216.697 8.65869C217.552 8.65869 217.953 9.05107 217.953 9.76159C217.953 10.3979 217.582 10.7054 217.099
                     10.769L218.025 12.3173H217.335L216.45 10.8221H215.976V12.3173H215.358V8.65869H216.697ZM215.987
                     9.23352V10.2516V10.2622H216.46C216.862 10.2622 217.304 10.2304 217.304 9.74255C217.304 9.24412 216.862
                     9.22291 216.46 9.23352H215.987Z"
                    fill="#368727"
                    fill-opacity="0.9"
                />
                <path
                    d="M154.723 19.1941H149.646V28.5479H147V6.76345H154.477C158.2 6.76345 161.861 8.24037 161.861 12.8557C161.861
                     17.0711 158.631 19.1941 154.723 19.1941ZM154.569 9.04036H149.646V16.9172H154.661C156.877 16.9172 159.031 15.6865
                     159.031 12.8865C159.031 10.0865 156.754 9.04036 154.569 9.04036Z"
                    fill="#368727"
                />
                <path
                    d="M178.575 8.08652V11.1327C176.544 9.34805 174.513 8.33267 172.39 8.33267C169.836 8.33267 168.083 9.77882 168.083
                     12.2096C168.083 14.7326 169.959 15.5942 172.575 16.3634C176.606 17.5634 179.036 19.1634 179.036 22.7633C179.036
                     26.9479 175.559 29.1633 171.744 29.1633C169.129 29.1633 166.913 28.3018 165.067 27.1326V23.9326C167.529 26.0249
                     169.713 27.0095 171.775 27.0095C174.483 27.0095 176.267 25.4095 176.267 22.9787C176.267 20.3941 174.083 19.4403
                     171.529 18.6711C167.559 17.5326 165.344 15.9019 165.344 12.2711C165.344 8.36344 168.575 6.14807 172.544
                     6.14807C175.006 6.14807 176.975 6.94807 178.575 8.08652Z"
                    fill="#368727"
                />
                <path
                    d="M195.967 10.6096L190.521 28.5479H187.874L181.351 6.76345H184.244L189.259 24.6095H189.351L194.613
                     6.76345H197.444L202.828 24.6095H202.921L207.936 6.76345H210.705L204.151 28.5479H201.536L196.028 10.6096H195.967Z"
                    fill="#368727"
                />
                <defs>
                    <clipPath id="clip0_6511_548">
                        <rect
                            width="127"
                            height="29.704"
                            fill="white"
                            transform="translate(0 6.14807)"
                        />
                    </clipPath>
                </defs>
            </svg>
        `;
    }
    // this removes the shadow dom
    public createRenderRoot() {
        return this;
    }
}
