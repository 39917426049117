/*
 * Copyright 2021-2023, FMR LLC.
 * All Rights Reserved.
 * Fidelity Confidential Information
 */

import {
    LitElement,
    html,
    TemplateResult
} from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { injectCss } from '@fmr-ap123285/dp-microfrontend-utils';
import ComponentStyles from './component.scss';

/**
 * This is a feature of TypeScript and not specific to LitElement.
 * This declaration helps TypeScript to provide strong typing when interacting with DOM APIs.
 * The JavaScript DOM API of course does not know or care about types,
 * but TypeScript does. With this mechanism you can add the type of your custom elements to the DOM APIs
 */
declare global {
    interface HTMLElementTagNameMap {
        'dgo-navbar-utility-nav': DgoUtilityNavbar;
    }
}
/**
 * DgoUtilityNavbar component that extends the LitElement base class.
 * @memberOf DgoUtilityNavbar
 */
@injectCss(ComponentStyles)
@customElement('dgo-navbar-utility-nav')
export class DgoUtilityNavbar extends LitElement {

    @property({ type: String })
    public logoLink: string | undefined = '';

    @property({ type: String })
    public logoutLink: string | undefined = '';

    /**
     * CreateRenderRoot method : Removes the shadow dom
     * @memberOf DgoUtilityNavbar
     */
    public createRenderRoot() {
        return this;
    }

    public connectedCallback() {
        super.connectedCallback();
    }

    /**
     * The render function defines a template literal and
     * makes use of the utility navbar element using this below template as if it were part of the HTML
     * @memberOf DgoUtilityNavbar
     */

    /* eslint-disable max-len*/
    public render(): TemplateResult {
        return html`
        <div class="utility-nav">
            <div class="wrapper">
                <psw-navbar-pvd-link pvd-id="logo" pvd-href=${this.logoLink} pvd-aria-label="Digital Client Onboarding">
                    <dgo-logo></dgo-logo>
                </psw-navbar-pvd-link>
                
                <div title="Log out">
                    <psw-navbar-pvd-button pvd-id="logout" pvd-variant="tertiary" pvd-icon-left="action__logout" pvd-native-anchor-attributes='{"aria-label":"logout"}'
                        pvd-href=${this.logoutLink}>Log out</psw-navbar-pvd-button>
                </div>
               
            </div>
        </div>
        `;
    }
}
