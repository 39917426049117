// import all pvd js you need
import {
    injectCustomSpriteSheet,
    ICON_STATUS_NOTIFICATION,
    ICON_NAV_PROFILE,
    ICON_ACTION_CARET_RIGHT,
    ICON_ACTION_CARET_DOWN,
    ICON_ALERT_CRITICAL,
    ICON_ALERT_INFO,
    PvdIcon,
    PvdLink,
    PvdExpandCollapse,
    PvdSpinner,
    PvdInlineAlert,
    PvdFieldGroup,
    PvdSelect,
    PvdMicrocopy,
    PvdTitle,
    PvdButton,
    ICON_ACTION_OPEN_LINK,
    ICON_ACTION_LOGOUT
} from '@fmr-ap109253/providence';
// *********
// IMPORTANT: Use the same namespace here and in SCSS as $pvd-class-prefix
// *********
const pvdNamespace = 'psw-navbar-pvd';
injectCustomSpriteSheet([
    ICON_STATUS_NOTIFICATION,
    ICON_NAV_PROFILE,
    ICON_ACTION_CARET_RIGHT,
    ICON_ACTION_CARET_DOWN,
    ICON_ALERT_CRITICAL,
    ICON_ALERT_INFO,
    ICON_ACTION_OPEN_LINK,
    ICON_ACTION_LOGOUT
], pvdNamespace);
PvdIcon.defineCustomElement(pvdNamespace);
PvdLink.defineCustomElement(pvdNamespace);
PvdExpandCollapse.defineCustomElement(pvdNamespace);
PvdSpinner.defineCustomElement(pvdNamespace);
PvdInlineAlert.defineCustomElement(pvdNamespace);
PvdFieldGroup.defineCustomElement(pvdNamespace);
PvdSelect.defineCustomElement(pvdNamespace);
PvdMicrocopy.defineCustomElement(pvdNamespace);
PvdTitle.defineCustomElement(pvdNamespace);
PvdButton.defineCustomElement(pvdNamespace);
