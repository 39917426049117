import { LitElement, html, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';

declare global {
    interface HTMLElementTagNameMap {
        'dgo-logo': DgoLogo;
    }
}

@customElement('dgo-logo')
export class DgoLogo extends LitElement {
    /* eslint-disable max-len*/
    public render(): TemplateResult {
        return html`
        <svg width="424" height="38" viewBox="0 0 424 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1742_3464)">
        <path d="M8.57398 29.885H0.5L6.96696 6.81H23.5167L21.9421 12.2855H13.4987L12.4166 16.2124H20.8599L19.4343 21.2537H10.991L8.58046 29.885H8.57398Z" fill="#368727"/>
        <path d="M27.2556 29.885H19.2723L24.0027 13.0242H31.9859L27.2556 29.885ZM32.4201 11.4172H24.4433L25.7393 6.81H33.7161L32.4201 11.4172Z" fill="#368727"/>
        <path d="M47.959 29.885H40.1312L40.6885 27.9993C39.1139 29.3277 37.5004 30.2868 34.9343 30.2868C32.3683 30.2868 30.6964 29.0491 30.6964 26.4182C30.6964 23.5088 31.8434 19.1802 33.5411 16.1476C34.7788 13.8926 37.0079 12.7132 39.6323 12.7132C41.8873 12.7132 43.5591 13.7046 44.2071 14.9423L46.4362 6.81H54.4194L47.9525 29.885H47.959ZM41.9585 18.0397C41.2458 18.0397 40.533 18.26 40.1312 19.245C39.5415 20.6706 39.0167 22.0573 39.0167 23.4504C39.0167 24.0984 39.4832 24.6557 40.2543 24.6557C40.8699 24.6557 41.2782 24.4678 41.6151 24.2539L43.2221 18.7136C42.9435 18.3119 42.451 18.0332 41.9521 18.0332L41.9585 18.0397Z" fill="#368727"/>
        <path d="M70.4896 22.5238H59.4803C59.2664 23.418 59.0461 24.0401 59.0461 24.7529C59.0461 25.0963 59.1044 25.9582 60.219 25.9582C61.3335 25.9582 61.826 25.524 62.2925 23.8846H70.1203C69.1289 28.6797 63.4395 30.3192 59.9468 30.3192C55.2165 30.3192 51.5359 29.6388 51.5359 25.0899C51.5359 22.5821 52.8059 18.4415 54.4713 16.2448C56.4801 13.6139 59.9792 12.629 63.1933 12.629C68.1439 12.629 71.358 13.6463 71.358 17.8583C71.358 18.8821 70.9238 21.1371 70.4896 22.5303V22.5238ZM62.5064 16.8668C61.515 16.8668 60.8346 17.5472 60.4004 19.245H63.6792C63.77 18.908 63.8931 18.3767 63.8931 18.0721C63.8931 17.1455 63.3358 16.8668 62.4999 16.8668H62.5064Z" fill="#368727"/>
        <path d="M78.5053 29.885H70.522L76.989 6.81H84.9723L78.5053 29.885Z" fill="#368727"/>
        <path d="M89.0546 29.885H81.0714L85.8017 13.0242H93.785L89.0546 29.885ZM94.2191 11.4172H86.2358L87.5318 6.81H95.5151L94.2191 11.4172Z" fill="#368727"/>
        <path d="M108.566 13.0242L109.259 16.828H103.148L101.354 23.2301C101.198 23.7226 101.075 24.3447 101.075 24.7464C101.075 25.4916 101.386 25.7378 102.468 25.7378H104.172L102.999 29.885H95.8845C93.5323 29.885 92.638 28.7381 92.638 27.0079C92.638 26.2951 92.7287 25.524 92.9815 24.6557L97.31 9.28532H105.293L104.244 13.0307H108.572L108.566 13.0242Z" fill="#368727"/>
        <path d="M119.335 13.0242L115.007 22.3683L115.13 13.0242H107.056L108.605 29.885C108.41 30.87 108.08 31.4272 107.464 31.7059C106.784 32.0104 105.254 31.9521 104.717 31.9262H104.619L103.349 36.514H108.799C112.013 36.514 113.594 34.9005 115.882 31.3171L127.513 13.0372H119.348L119.335 13.0242Z" fill="#368727"/>
        </g>
        <path d="M417.737 16.854H417.799C418.729 14.901 420.527 14.064 422.759 14.064H423.224V16.327C422.914 16.265 422.573 16.234 422.263 16.234C420.217 16.234 418.946 17.35 417.861 19.303V29.378H415.35V17.691C415.35 16.482 415.257 15.521 415.071 14.498H417.52C417.644 15.118 417.737 15.986 417.737 16.854Z" fill="#368727"/>
        <path d="M410.352 28.231C408.368 29.409 406.632 29.843 404.524 29.843C399.626 29.843 396.433 26.836 396.433 21.876C396.433 17.102 399.657 14.033 403.873 14.033C408.678 14.033 411.065 17.536 411.065 21.38C411.065 21.783 411.034 22.217 410.972 22.527H399.037C399.347 25.782 401.207 27.828 404.586 27.828C406.663 27.828 408.368 27.208 410.352 25.689V28.231ZM403.904 16.079C401.052 16.079 399.316 18.28 399.037 20.698H408.678C408.43 18.063 406.818 16.079 403.904 16.079Z" fill="#368727"/>
        <path d="M394.502 29.378C393.882 29.564 393.045 29.75 392.301 29.75C389.604 29.75 388.147 28.231 388.147 25.689V16.606H385.326V14.498H388.147V10.468H390.596V14.498H394.533V16.606H390.596V25.193C390.596 27.022 391.526 27.704 392.952 27.704C393.448 27.704 394.068 27.58 394.502 27.425V29.378Z" fill="#368727"/>
        <path d="M371.753 16.141H371.784C373.551 14.715 374.822 14.064 376.899 14.064C380.464 14.064 382.51 16.358 382.51 19.706V29.378H379.999V19.892C379.999 17.877 378.759 16.11 376.341 16.11C374.729 16.11 373.427 16.885 371.846 18.466V29.378H369.335V17.691C369.335 16.482 369.242 15.521 369.056 14.498H371.505C371.629 14.963 371.722 15.645 371.753 16.141Z" fill="#368727"/>
        <path d="M364.337 28.231C362.353 29.409 360.617 29.843 358.509 29.843C353.611 29.843 350.418 26.836 350.418 21.876C350.418 17.102 353.642 14.033 357.858 14.033C362.663 14.033 365.05 17.536 365.05 21.38C365.05 21.783 365.019 22.217 364.957 22.527H353.022C353.332 25.782 355.192 27.828 358.571 27.828C360.648 27.828 362.353 27.208 364.337 25.689V28.231ZM357.889 16.079C355.037 16.079 353.301 18.28 353.022 20.698H362.663C362.415 18.063 360.803 16.079 357.889 16.079Z" fill="#368727"/>
        <path d="M347.635 24.17V27.301C345.403 29.068 342.644 29.998 339.668 29.998C333.158 29.998 328.384 25.224 328.384 18.404C328.384 11.615 333.189 6.81 339.637 6.81C342.458 6.81 345.217 7.647 347.263 9.135V12.173C344.814 10.189 342.458 9.166 339.885 9.166C334.77 9.166 331.236 13.165 331.236 18.404C331.236 23.643 334.739 27.673 339.916 27.673C342.582 27.673 345.279 26.526 347.635 24.17Z" fill="#368727"/>
        <path d="M309.513 15.924C307.157 15.924 305.638 17.288 305.638 19.613C305.638 21.969 307.188 23.333 309.513 23.333C311.869 23.333 313.388 21.938 313.388 19.613C313.388 17.319 311.869 15.924 309.513 15.924ZM317.759 31.796C317.759 34.989 314.845 37.19 309.637 37.19C305.111 37.19 302.29 35.609 302.29 32.788C302.29 31.083 303.375 29.874 304.987 29.254V29.192C304.057 28.82 303.499 27.766 303.499 26.805C303.499 25.472 304.181 24.604 305.111 23.86C303.933 22.992 303.22 21.597 303.22 19.613C303.22 16.017 305.855 14.064 309.513 14.064C311.28 14.064 312.272 14.498 313.822 14.498H318.162V16.606H314.752V16.668C315.372 17.288 315.837 18.28 315.837 19.644C315.837 23.023 313.171 25.131 309.482 25.131C308.428 25.131 307.436 24.976 306.537 24.666C306.041 25.1 305.731 25.658 305.731 26.216C305.731 26.867 306.227 27.549 307.374 27.549H312.489C316.395 27.549 317.759 29.44 317.759 31.796ZM311.9 29.719H308.149C305.669 29.719 304.677 31.114 304.677 32.509C304.677 34.307 306.723 35.299 309.885 35.299C313.357 35.299 315.341 33.997 315.341 32.137C315.341 30.556 314.225 29.719 311.9 29.719Z" fill="#368727"/>
        <path d="M288.168 16.141H288.199C289.966 14.715 291.237 14.064 293.314 14.064C296.879 14.064 298.925 16.358 298.925 19.706V29.378H296.414V19.892C296.414 17.877 295.174 16.11 292.756 16.11C291.144 16.11 289.842 16.885 288.261 18.466V29.378H285.75V17.691C285.75 16.482 285.657 15.521 285.471 14.498H287.92C288.044 14.963 288.137 15.645 288.168 16.141Z" fill="#368727"/>
        <path d="M279.209 10.22C278.341 10.22 277.628 9.569 277.628 8.639C277.628 7.74 278.341 7.058 279.209 7.058C280.046 7.058 280.759 7.74 280.759 8.639C280.759 9.569 280.015 10.22 279.209 10.22ZM277.938 29.378V14.498H280.449V29.378H277.938Z" fill="#368727"/>
        <path d="M270.154 25.472V18.342C268.325 16.792 267.085 16.11 265.039 16.11C262.125 16.11 260.172 18.714 260.172 21.907C260.172 25.162 262.156 27.797 265.132 27.797C267.147 27.797 268.542 27.053 270.154 25.472ZM270.154 27.735H270.123C268.697 28.975 267.333 29.812 264.915 29.812C260.668 29.812 257.63 26.743 257.63 22C257.63 17.164 260.792 14.064 264.853 14.064C267.209 14.064 268.759 14.87 270.123 15.831H270.154V7.42999H272.696V29.378H270.154V27.735Z" fill="#368727"/>
        <path d="M250.416 16.854H250.478C251.408 14.901 253.206 14.064 255.438 14.064H255.903V16.327C255.593 16.265 255.252 16.234 254.942 16.234C252.896 16.234 251.625 17.35 250.54 19.303V29.378H248.029V17.691C248.029 16.482 247.936 15.521 247.75 14.498H250.199C250.323 15.118 250.416 15.986 250.416 16.854Z" fill="#368727"/>
        <path d="M240.43 22.124L235.935 22.558C233.765 22.775 232.804 23.705 232.804 25.348C232.804 27.177 234.137 27.952 235.842 27.952C237.268 27.952 238.57 27.332 240.43 25.782V22.124ZM234.602 21.225L239.934 20.295C240.306 20.233 240.368 20.14 240.368 19.861V19.458C240.368 17.133 238.818 16.079 236.679 16.079C234.85 16.079 232.897 16.792 231.316 18.063V15.614C232.742 14.529 234.912 14.002 236.865 14.002C240.678 14.002 242.941 15.955 242.941 20.078V25.658C242.941 26.929 243.065 28.386 243.22 29.378H240.74C240.647 28.975 240.554 28.2 240.523 27.673H240.461C238.942 29.068 237.299 29.812 235.47 29.812C232.68 29.812 230.293 28.634 230.293 25.472C230.293 23.116 231.719 21.721 234.602 21.225Z" fill="#368727"/>
        <path d="M211.402 21.969C211.402 17.164 214.843 14.033 219.245 14.033C223.678 14.033 227.088 17.04 227.088 21.907C227.088 26.712 223.678 29.843 219.245 29.843C214.843 29.843 211.402 26.805 211.402 21.969ZM213.944 21.907C213.944 25.193 216.021 27.797 219.245 27.797C222.469 27.797 224.546 25.193 224.546 21.969C224.546 18.714 222.469 16.11 219.245 16.11C216.021 16.11 213.944 18.683 213.944 21.907Z" fill="#368727"/>
        <path d="M195.683 16.079H195.745C197.419 14.746 198.938 14.064 200.922 14.064C205.169 14.064 208.207 17.133 208.207 21.814C208.207 26.464 205.169 29.812 200.891 29.812C197.45 29.812 196.086 27.983 195.683 27.983C195.528 27.983 195.466 28.138 195.466 28.355V29.378H193.172V7.42999H195.683V16.079ZM205.665 21.876C205.665 18.559 203.588 16.11 200.643 16.11C198.845 16.11 197.481 16.854 195.683 18.497V25.472C196.923 26.712 198.349 27.797 200.519 27.797C203.588 27.797 205.665 25.193 205.665 21.876Z" fill="#368727"/>
        <path d="M177.367 16.141H177.398C179.165 14.715 180.436 14.064 182.513 14.064C186.078 14.064 188.124 16.358 188.124 19.706V29.378H185.613V19.892C185.613 17.877 184.373 16.11 181.955 16.11C180.343 16.11 179.041 16.885 177.46 18.466V29.378H174.949V17.691C174.949 16.482 174.856 15.521 174.67 14.498H177.119C177.243 14.963 177.336 15.645 177.367 16.141Z" fill="#368727"/>
        <path d="M147.5 18.404C147.5 11.553 152.398 6.81 159.001 6.81C165.604 6.81 170.471 11.646 170.471 18.497C170.471 25.348 165.573 29.998 159.001 29.998C152.398 29.998 147.5 25.255 147.5 18.404ZM150.352 18.404C150.352 23.581 153.886 27.673 159.001 27.673C164.116 27.673 167.65 23.643 167.65 18.497C167.65 13.289 164.054 9.166 159.001 9.166C153.917 9.166 150.352 13.227 150.352 18.404Z" fill="#368727"/>
        <defs>
        <clipPath id="clip0_1742_3464">
        <rect width="127" height="29.704" fill="white" transform="translate(0.5 6.81)"/>
        </clipPath>
        </defs>
        </svg>
        `;
    }
    // this removes the shadow dom
    public createRenderRoot() {
        return this;
    }
}
